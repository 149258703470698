import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import Slider from "react-slick";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import FinancialService from '../../../api/services/FinancialService';
import ReportDetails from '../../customComponent/ReportDetails/ReportDetails';
import application_contants from '../../constants/application_contant';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import FinancialType from '../../Utils/Json/financialType';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import SEOComponent from '../../customComponent/SEO/SEOComponent';


const Financials = () => {
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [annualReport, setAnnualReport] = useState([]);
    const [financialReport, setFinancialReport] = useState([]);
    const [FCRAReport, setFCRAReport] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, [])

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowForwardIosOutlined className="lr-arrow-green" />
            </div>
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowBackIosOutlined className="lr-arrow-green" />
            </div>
        );
    }


    const getList = async () => {
        await FinancialService.getFinancialList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {

                                let annualReportArray = [];
                                let financialReportArray = [];
                                let FCRAReportArray = [];
                                if (result.data) {
                                    result.data.list.map((it) => {
                                        if (it.reportType === 3) {
                                            FCRAReportArray.push(it)
                                        } else if (it.reportType === 1) {
                                            annualReportArray.push(it)
                                        }
                                        else if (it.reportType === 2) {
                                            financialReportArray.push(it)
                                        }
                                    });
                                }

                                setAnnualReport([...annualReportArray]);
                                setFinancialReport([...financialReportArray]);
                                setFCRAReport([...FCRAReportArray]);
                                setIsLoading(false);

                            } else {

                                setAnnualReport([]);
                                setFinancialReport([]);
                                setFCRAReport([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    const reportSettings = {
        className: "center",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: width >= 1025
            ? 3 : width >= 1024 && width <= 1024 ? 2
                : width >= 768 && width <= 1023
                    ? 2
                    : 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const handlePreviewPdf = (item) => {
        window.open(
            `${ApiConfig.URL}${item.path}`,
            "_blank"
        );
    }

    return (
        <ExternalLayout>
            <SEOComponent title="Financial Report | Annual Report | FCRA Report" subtitle="Going to School" description="All reports are available for download. If you're having trouble downloading, please email us."
            />
            <RenderPage
                id="about-page"
                className="render-page"
                containerType="container-fluid px-0"
            >
                <Strip
                    id="tst-about"
                    className="strip strip-no-padding"
                    containerType="container-fluid px-0"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                            <img src={application_contants.waveBannerImg} alt="" />
                        </Col>
                    </Row>
                </Strip>
                <Strip
                    id="tst-team"
                    className="strip strip-no-padding"
                    containerType={`${width >= 768 ? 'container' : 'container-fluid'}`}
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto tab-mobile--position">
                            <Nav tabs className="tab-container tab-container--position">
                                {FinancialType.map((it, index) => (
                                    <NavItem className="tab-nav-item" key={index}>
                                        <NavLink
                                            className={`tab-nav-link ${classnames({ active: activeTab === index })}`}
                                            onClick={() => { toggle(index); }}
                                        >
                                            {it.label}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeTab}>


                                <TabPane tabId={0} className="pt-4">
                                    <Row className="pt-5 mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                            {width >= application_contants.MOBILE_BREAKPOINT ?
                                                <Row className="mx-0">
                                                    {annualReport.length > 0 && annualReport?.sort((a, b) => b?.startYear - a?.endYear)?.map((item, index) => (
                                                        <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                            <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={false} />
                                                        </Col>
                                                    ))}
                                                </Row> :
                                                <Row className="mx-0">
                                                    {annualReport.length >= 3 ?
                                                        <Slider {...reportSettings}>
                                                            {annualReport.length > 0 && annualReport?.sort((a, b) => b?.startYear - a?.endYear)?.map((item, index) => (
                                                                <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                                    <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={false} />
                                                                </Col>
                                                            ))}
                                                        </Slider> :
                                                        <>
                                                            {annualReport.length > 0 && annualReport?.sort((a, b) => b?.startYear - a?.endYear)?.map((item, index) => (
                                                                <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                                    <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={false} />
                                                                </Col>
                                                            ))}
                                                        </>}
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={1} className="pt-4">
                                    <Row className="pt-5 mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                            {width >= application_contants.MOBILE_BREAKPOINT ?
                                                <Row className="mx-0">
                                                    {financialReport.length > 0 && financialReport?.sort((a, b) => b?.startYear - a?.endYear)?.map((item, index) => (
                                                        <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                            <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={true} />
                                                        </Col>
                                                    ))}
                                                </Row> :
                                                <Row className="mx-0">
                                                    {financialReport.length >= 3 ?
                                                        <Slider {...reportSettings}>
                                                            {financialReport.length > 0 && financialReport?.sort((a, b) => b?.startYear - a?.endYear)?.map((item, index) => (
                                                                <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                                    <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={true} />
                                                                </Col>
                                                            ))}
                                                        </Slider> :
                                                        <>
                                                            {financialReport.length > 0 && financialReport?.sort((a, b) => b?.startYear - a?.endYear)?.map((item, index) => (
                                                                <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                                    <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={true} />
                                                                </Col>
                                                            ))}
                                                        </>}
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2} className="pt-4">
                                    <Row className="pt-5 mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                            {width >= application_contants.MOBILE_BREAKPOINT ?
                                                <Row className="mx-0">
                                                    {FCRAReport.length > 0 && FCRAReport.map((item, index) => (
                                                        <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                            <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={false} />
                                                        </Col>
                                                    ))}
                                                </Row> :
                                                <Row className="mx-0">
                                                    {FCRAReport.length >= 3 ?
                                                        <Slider {...reportSettings}>
                                                            {FCRAReport.length > 0 && FCRAReport.map((item, index) => (
                                                                <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                                    <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={false} />
                                                                </Col>
                                                            ))}
                                                        </Slider> :
                                                        <>
                                                            {FCRAReport.length > 0 && FCRAReport.map((item, index) => (
                                                                <Col xs={6} sm={6} md={4} lg={width === 1024 ? 4 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 card-reports" key={index}>
                                                                    <ReportDetails item={item} handlePreviewPdf={handlePreviewPdf} isTitle={false} />
                                                                </Col>
                                                            ))}
                                                        </>}
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Strip>

            </RenderPage>
        </ExternalLayout>
    )
}


export default Financials;