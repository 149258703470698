import React from "react";
import memoizeOne from "memoize-one";
import { Button } from "reactstrap";
import FinancialType from "../../../Utils/Json/financialType";

const TablesColumns = memoizeOne((handleEdit, handleDelete, handlePosition) => [
    {
        name: "Id",
        selector: "id",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{row.id}</p></div>,
    },
    {
        name: "ReportType",
        selector: "name",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{row.reportType ? FinancialType?.[row?.reportType - 1]?.label : ''}</p></div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-small external-section--title title-black-pearl">{`${row.status === 1 ? 'Active' : 'InActive'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleDelete(row)}>
                    <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">Delete</Button>
                </div>
                <div onClick={() => handleEdit(row)} className="ml-12px">
                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">Edit</Button>
                </div>
                {/* <div onClick={() => handlePosition(row)} className="ml-12px">
                    <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">Position</Button>
                </div> */}
            </div>
        ),
    },

]);




export default TablesColumns;
