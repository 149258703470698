import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import FinancialType from '../../Utils/Json/financialType';

const ReportDetails = (props) => {
    //  report-card-container 
    const { item, handlePreviewPdf, isTitle } = props;
    return (
        <Row className="mx-0">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="px-0">
                <div className="report-card-container  card__effect" style={{ backgroundColor: `#${item.colorCode}` }} onClick={() => handlePreviewPdf(item)}>
                    {item.id === 13 || item.id === 14 ?
                        <div>
                            <h5 className="external-title title-white robotoSlab-Medium">{item.id === 13 ? 'Renewal-Certificate Going to School Fund FCRA' : 'Quarterly Receipt of Foreign Contribution'}</h5>
                        </div>
                        : <div>
                            <h5 className="external-title title-white robotoSlab-Medium">{item.reportType ? FinancialType?.[item?.reportType - 1]?.label : ''}</h5>
                            <h5 className="external-title title-white robotoSlab-Medium">{item.startYear} - {item.endYear} {item.name}</h5>
                        </div>}
                    {/* {isTitle ?
                        <div>
                            <h6 className="external-section--title title-white pt-2">{`Quarter - 1`}</h6>
                        </div> : null} */}
                    <div className="view-container">
                        <h6 className="h6-small external-section--title title-white align-center robotoSlab-Medium">View</h6>
                    </div>
                </div>
            </Col>
        </Row>
    )
};


export default ReportDetails;